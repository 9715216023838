@charset "UTF-8";
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
}

html {
  overflow-y: scroll;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

button, input, select, textarea {
  margin: 0;
}

button,
input[type=button] {
  width: auto;
  overflow: visible;
}

@keyframes spin-around {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes slideDown {
  from {
    max-height: 0px;
  }
  to {
    max-height: 300px;
  }
}

@font-face {
  font-family: 'lari';
  src: url("../fonts/lari.eot?o6wdu3");
  src: url("../fonts/lari.eot?o6wdu3#iefix") format("embedded-opentype"), url("../fonts/lari.ttf?o6wdu3") format("truetype"), url("../fonts/lari.woff?o6wdu3") format("woff"), url("../fonts/lari.svg?o6wdu3#lari") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="licon-"], [class*=" licon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lari' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.licon-eye-closed:before {
  content: "";
}

.licon-align_text_distribute:before {
  content: "";
}

.licon-menu:before {
  content: "";
}

.licon-notebook:before {
  content: "";
}

.licon-done:before {
  content: "";
}

.licon-download:before {
  content: "";
}

.licon-grid_view:before {
  content: "";
}

.licon-help:before {
  content: "";
}

.licon-new_tab:before {
  content: "";
}

.licon-shut_down:before {
  content: "";
}

.licon-bag_minus_play_round:before {
  content: "";
}

.licon-bag_minus:before {
  content: "";
}

.licon-bag_close_round:before {
  content: "";
}

.licon-bag_close_play_round-2:before {
  content: "";
}

.licon-bag_circle_play_round:before {
  content: "";
}

.licon-bag_circle:before {
  content: "";
}

.licon-attachment-skew:before {
  content: "";
}

.licon-attachment:before {
  content: "";
}

.licon-atom:before {
  content: "";
}

.licon-back-to-list:before {
  content: "";
}

.licon-arrow_repeat:before {
  content: "";
}

.licon-arrow_left:before {
  content: "";
}

.licon-move:before {
  content: "";
}

.licon-fullscreen:before {
  content: "";
}

.licon-apple:before {
  content: "";
}

.licon-infinite-1:before {
  content: "";
}

.licon-infinite:before {
  content: "";
}

.licon-bag_play_round:before {
  content: "";
}

.licon-bag_plus:before {
  content: "";
}

.licon-bag_plus_play_round:before {
  content: "";
}

.licon-bug:before {
  content: "";
}

.licon-bug_spider:before {
  content: "";
}

.licon-bug_spider_2:before {
  content: "";
}

.licon-calendar-fill:before {
  content: "";
}

.licon-calendar-date:before {
  content: "";
}

.licon-calendar:before {
  content: "";
}

.licon-1322:before {
  content: "";
}

.licon-cart:before {
  content: "";
}

.licon-cart-2:before {
  content: "";
}

.licon-cart-fill:before {
  content: "";
}

.licon-cart_close:before {
  content: "";
}

.licon-cart_close_round:before {
  content: "";
}

.licon-cart_down_round:before {
  content: "";
}

.licon-cart_empty:before {
  content: "";
}

.licon-cart_fill:before {
  content: "";
}

.licon-cart_fill-2:before {
  content: "";
}

.licon-cart_minus_round:before {
  content: "";
}

.licon-cart_play_round:before {
  content: "";
}

.licon-cart_plus_round:before {
  content: "";
}

.licon-cart_round:before {
  content: "";
}

.licon-cart_round-2:before {
  content: "";
}

.licon-cart_round-fill:before {
  content: "";
}

.licon-cart_up_round-2:before {
  content: "";
}

.licon-chart:before {
  content: "";
}

.licon-click:before {
  content: "";
}

.licon-clock:before {
  content: "";
}

.licon-clock-square:before {
  content: "";
}

.licon-cloud_close:before {
  content: "";
}

.licon-cloud_down:before {
  content: "";
}

.licon-cloud_minus:before {
  content: "";
}

.licon-cloud_ok:before {
  content: "";
}

.licon-cloud_plus:before {
  content: "";
}

.licon-cloud_up:before {
  content: "";
}

.licon-cloud:before {
  content: "";
}

.licon-code:before {
  content: "";
}

.licon-coffee:before {
  content: "";
}

.licon-controller:before {
  content: "";
}

.licon-crop-image:before {
  content: "";
}

.licon-crop:before {
  content: "";
}

.licon-delete:before {
  content: "";
}

.licon-desktop:before {
  content: "";
}

.licon-dollar:before {
  content: "";
}

.licon-dropbox:before {
  content: "";
}

.licon-edit:before {
  content: "";
}

.licon-edit-2:before {
  content: "";
}

.licon-email:before {
  content: "";
}

.licon-email-2:before {
  content: "";
}

.licon-euro:before {
  content: "";
}

.licon-facebook:before {
  content: "";
}

.licon-female:before {
  content: "";
}

.licon-file_player_media:before {
  content: "";
}

.licon-github:before {
  content: "";
}

.licon-google:before {
  content: "";
}

.licon-google_drive:before {
  content: "";
}

.licon-google_plus:before {
  content: "";
}

.licon-home:before {
  content: "";
}

.licon-image:before {
  content: "";
}

.licon-instagram:before {
  content: "";
}

.licon-link_round:before {
  content: "";
}

.licon-link:before {
  content: "";
}

.licon-linkedin:before {
  content: "";
}

.licon-lire:before {
  content: "";
}

.licon-list-square:before {
  content: "";
}

.licon-list:before {
  content: "";
}

.licon-love-fill:before {
  content: "";
}

.licon-love:before {
  content: "";
}

.licon-male:before {
  content: "";
}

.licon-message:before {
  content: "";
}

.licon-minus_circle:before {
  content: "";
}

.licon-money_round-2:before {
  content: "";
}

.licon-money:before {
  content: "";
}

.licon-money_round:before {
  content: "";
}

.licon-mouse:before {
  content: "";
}

.licon-mouse_window:before {
  content: "";
}

.licon-sites:before {
  content: "";
}

.licon-next_arrow:before {
  content: "";
}

.licon-notification:before {
  content: "";
}

.licon-pin:before {
  content: "";
}

.licon-pin-2:before {
  content: "";
}

.licon-pin_close:before {
  content: "";
}

.licon-pin_minus:before {
  content: "";
}

.licon-pin_plus:before {
  content: "";
}

.licon-planet_system:before {
  content: "";
}

.licon-plus_circle:before {
  content: "";
}

.licon-profile:before {
  content: "";
}

.licon-profile_close_round:before {
  content: "";
}

.licon-profile_image_close_round:before {
  content: "";
}

.licon-profile_image_favorite_round:before {
  content: "";
}

.licon-profile_image_minus_round:before {
  content: "";
}

.licon-profile_image_plus_round:before {
  content: "";
}

.licon-profile_image_round:before {
  content: "";
}

.licon-profile_minus:before {
  content: "";
}

.licon-profile_plus_round:before {
  content: "";
}

.licon-search_left:before {
  content: "";
}

.licon-search_left-2:before {
  content: "";
}

.licon-search_right:before {
  content: "";
}

.licon-search_right-2:before {
  content: "";
}

.licon-external:before {
  content: "";
}

.licon-settings:before {
  content: "";
}

.licon-share:before {
  content: "";
}

.licon-shop_center:before {
  content: "";
}

.licon-shop_center_round:before {
  content: "";
}

.licon-shopping_bag:before {
  content: "";
}

.licon-shopping_bag_2:before {
  content: "";
}

.licon-shopping_bag_close:before {
  content: "";
}

.licon-shopping_bag_minus:before {
  content: "";
}

.licon-shopping_bag_play:before {
  content: "";
}

.licon-shopping_bag_plus:before {
  content: "";
}

.licon-shopping_cart:before {
  content: "";
}

.licon-shopping_cart_2:before {
  content: "";
}

.licon-shopping_cart_close:before {
  content: "";
}

.licon-shopping_cart_close_round:before {
  content: "";
}

.licon-shopping_cart_love:before {
  content: "";
}

.licon-shopping_cart_minus:before {
  content: "";
}

.licon-shopping_cart_minus_round:before {
  content: "";
}

.licon-shopping_cart_plus:before {
  content: "";
}

.licon-shopping_cart_plus_round:before {
  content: "";
}

.licon-shopping_cart_round:before {
  content: "";
}

.licon-sketch:before {
  content: "";
}

.licon-skype:before {
  content: "";
}

.licon-slack:before {
  content: "";
}

.licon-star_favorite:before {
  content: "";
}

.licon-star_favorite-fill:before {
  content: "";
}

.licon-star_favorite_half:before {
  content: "";
}

.licon-star_favorite_half-fill:before {
  content: "";
}

.licon-stats:before {
  content: "";
}

.licon-1191:before {
  content: "";
}

.licon-stats-circle:before {
  content: "";
}

.licon-stats:before {
  content: "";
}

.licon-tag_fill:before {
  content: "";
}

.licon-tag:before {
  content: "";
}

.licon-tag-2:before {
  content: "";
}

.licon-tap_click_force_touch:before {
  content: "";
}

.licon-twitter:before {
  content: "";
}

.licon-eye:before {
  content: "";
}

.licon-wordpress:before {
  content: "";
}

.licon-youtube:before {
  content: "";
}

html,
body {
  height: 100vh;
}

a {
  text-decoration: none;
}

* {
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-family: "Roboto", Helvetica Neue, "Arial", sans-serif;
}

body {
  background: #fafcfd url(../images/login.png) no-repeat center center;
  font-family: "Roboto", Helvetica Neue, "Arial", sans-serif;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

body,
h1 {
  font-weight: 500;
}

h1 {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.form {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 46px 0 #d3e5ee;
  padding: 30px;
  width: 340px;
}

.group {
  padding: 10px 0;
  position: relative;
}

.group .input,
.group label {
  color: #343434;
  font-size: 14px;
}

.input {
  border: 2px solid #e5e7ef;
  border-radius: 50px;
  box-sizing: border-box;
  font-family: "Roboto", Helvetica Neue, "Arial", sans-serif;
  padding: 10px 20px;
  width: 100%;
}

.input--checkbox {
  padding-bottom: 20px;
}

.input--checkbox label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input--checkbox label:before {
  border: 2px solid #e5e7ef;
  border-radius: 2px;
  content: " ";
  display: inline-block;
  margin-right: 10px;
  height: 16px;
  width: 16px;
  transition: all .5s linear;
}

.input--checkbox input[type="checkbox"] {
  display: none;
}

.input--checkbox input[type="checkbox"]:checked + label:before {
  background: url(../images/icon-check.png) no-repeat center center;
}

.button {
  background: #3174e1;
  border: 0;
  border-radius: 50px;
  color: #fff;
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}

.button,
.input,
.button:active,
.input:active,
.input:focus,
.button:focus {
  outline: none;
}

.showPassword {
  color: #e5e7ef;
  cursor: pointer;
  display: block;
  font-size: 22px;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.showPassword.is-active {
  color: #153454;
}

.forgot {
  padding-top: 10px;
  text-align: center;
}

.forgot a {
  color: #a2a7b0;
}

.lead {
  color: #a2a7b0;
  line-height: 1.5em;
  padding-bottom: 20px;
  text-align: center;
}

.error:empty {
  display: none;
}

.success {
  color: #8bc34a;
  font-size: 15px;
  padding-bottom: 10px;
  text-align: center;
}
